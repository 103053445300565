<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA'"
        class="qrcode-wrap"
        :style="{display: showQrScaner}"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <v-row dense>
        <v-col
          :cols="$store.getters.getMobileModel !== 'PDA' ? 6 : 7"
        >
          <v-text-field
            id="input-pcode"
            v-model="qrKey"
            label="파레트번호"
            placeholder="QR 스캔"
            clearable
            autocomplete="off"
            @keyup.enter.prevent="onEnterPcode"
          />
        </v-col>
        <v-col
          v-if="$store.getters.getMobileModel !== 'PDA'"
          class="pb-0 pt-4 pl-0"
          cols="1"
        >
          <v-icon
            @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'; qrKey=null"
          >
            mdi-camera
          </v-icon>
        </v-col>
        <v-col
          :cols="5"
        >
          <v-text-field
            v-model="readQrKey"
            label="현 파레트번호"
            readonly
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="locationCode"
        dense
      >
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="locationCode"
            label="위치코드"
            :readonly="true"
            filled
            autocomplete="off"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="locationName"
            label="위치명"
            :readonly="true"
            filled
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <div style="margin-top:14px; background-color:black;">
        <dx-data-grid
          ref="refGoodsInputGrid"
          :data-source="goodsInputListInfo"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="gridHeight"
          :focused-row-enabled="true"
          :focused-row-key.sync="focusedRowKey"
          :auto-navigate-to-focused-row="false"
          key-expr="seqId"
          @focused-row-changed="onSelectionChanged"
        >
          <DxColumnFixing :enabled="true" />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
          />
          <DxColumn
            :allow-editing="false"
            caption="납품"
            data-field="수량"
            format="#,##0"
            width="70"
          />
          <DxColumn
            :allow-editing="false"
            caption="잔량"
            data-field="잔량"
            format="#,##0"
            width="70"
          />
          <DxColumn
            :allow-editing="true"
            caption="유효일"
            data-field="유효일"
            data-type="date"
            format="yy/MM/dd"
            width="100"
          />
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
            show-scrollbar="always"
            :use-native="true"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
      <div style="margin-top:14px; background-color:black;">
        <dx-data-grid
          ref="refGoodsInputGrid"
          :data-source="goodsReceiptList"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="gridHeight"
          :focused-row-enabled="true"
          :auto-navigate-to-focused-row="false"
          key-expr="seqId"
          @toolbar-preparing="onToolbarPreparing"
        >
          <DxColumnFixing :enabled="true" />
          <DxColumn
            :allow-editing="true"
            caption="일자"
            data-field="발생일자"
            data-type="date"
            format="yy/MM/dd"
            sort-order="desc"
            width="100"
          />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
          />
          <DxColumn
            :allow-editing="true"
            caption="구분"
            data-field="입출고구분"
            :visible="true"
            width="80"
          >
            <DxLookup
              :data-source="receiptLookup"
              display-expr="코드명"
              value-expr="코드"
            />
          </DxColumn>
          <DxColumn
            :allow-editing="false"
            caption="수량"
            data-field="수량"
            format="#,##0"
            width="70"
          />
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
            show-scrollbar="always"
            :use-native="true"
          />
          <dx-sorting mode="multiple" />
          <template #receiptTitle>
            <div
              class="toolbar-label"
              style="font-size:1.0rem;margin-left:0px;"
            >
              <b>{{ receiptTitle }}</b>
            </div>
          </template>
        </dx-data-grid>
      </div>
      <div>
        <loading
          :active.sync="isLoading"
          color="rgb(2, 110, 156)"
          loader="bars"
        />
      </div>
    </v-container>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
// eslint-disable-next-line no-unused-vars
import ConstDef from '../../../../BnhMesVueWin/src/share/ConstDef'
// eslint-disable-next-line no-unused-vars
import HttpService from '../../share/service/HttpService'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'
import DxDataGrid, { DxColumnFixing, DxScrolling, DxColumn, DxPaging, DxSorting, DxLookup } from 'devextreme-vue/data-grid'

export default {
  name: 'PurchaseDelivery',
  components: {
    Loading,
    QrCodeScanComponent,
    DxDataGrid,
    DxColumnFixing,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxLookup,
    DxSorting
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      qrKey: '',
      readQrKey: '',
      locationCode: null,
      locationName: null,
      selectedRow: null,
      goodsInputListInfo: null,
      focusedRowKey: null,
      // 입출고 내역
      goodsReceiptList: null,
      gridHeight: null,
      receiptLookup: null,
      receiptTitle: '입출고 현황',
      locationLookup: null,
      // qrcode scan
      showQrScaner: 'none'
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    // HttpService.reqPost(null, '/isLogin')
    this.receiptLookup = ConstDef.GOODS_INOUT_CODE_ALL
    this.locationLookup = this.$store.getters.getLocation
  },
  mounted () {
    this.gridHeight = (this.$Q('html').height() - 230) / 2
    console.log(this.gridHeight)
    this.initInput()
  },
  updated () {
  },
  methods: {
    initInput () {
      this.qrKey = null
      this.$Q('#input-pcode').focus()
    },
    onEnterPcode (e) {
      this.goodsReceiptList = null
      let split = null
      try {
        split = AppLib.base64DecodeSubstring(this.qrKey)
      } catch {
        this.$snotify.error('제품정보를 확인할 수 없습니다.')
        this.initInput()
        return
      }
      this.qrKey = split
      this.receiptTitle = '입출고 현황'
      this.$_sp.runNoEncodeFindProc('spFindAllPalletInfo', { 입고키: this.qrKey })
        .then((data) => {
          this.goodsInputListInfo = this.$_sp.MakeModel(data)
          console.log(this.goodsInputListInfo)
          if (this.goodsInputListInfo.length > 0) {
            this.locationCode = this.goodsInputListInfo[0].장소위치코드
            this.locationName = this.$_.findWhere(this.locationLookup, { 장소코드: this.locationCode }).장소명
            if (this.goodsInputListInfo.length === 1) {
              this.focusedRowKey = this.goodsInputListInfo[0].seqId
            }
            this.readQrKey = this.qrKey
            this.qrKey = ''
          }
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onPCodeScan (result) {
      this.qrKey = result
      this.onEnterPcode(null)
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRow = e.row.data
        this.receiptTitle = `${this.selectedRow.조인품명}`
        this.refreshGoodsReceiptList()
      }
    },
    refreshGoodsReceiptList () {
      this.$_sp.runNoEncodeFindSqlProc('창고수불', { 입고키: this.selectedRow.입고키, 품번: this.selectedRow.품번 })
        .then((data) => {
          this.goodsReceiptList = this.$_sp.MakeModel(data)
          console.log(this.goodsReceiptList)
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    // 자재 입출고
    onToolbarPreparing (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: 'receiptTitle'
        }
      )
    }
  }
}
</script>

<style lang="scss">

</style>
